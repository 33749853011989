<template>
    <div class="page-content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="
              page-title-box
              d-sm-flex
              align-items-center
              justify-content-between
            ">
                        <h4 class="mb-sm-0">Ganti Password</h4>

                        <div class="page-title-right">
                            <ol class="breadcrumb m-0">
                                <li class="breadcrumb-item">
                                    <router-link :to="{ name: 'user.profile' }">
                                        Profile
                                    </router-link>
                                </li>
                                <li class="breadcrumb-item active">Ganti Password</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header align-items-center d-flex">
                            <h4 class="card-title mb-0 flex-grow-1">Edit Password</h4>
                        </div>
                        <div class="card-body">
                            <form class="" @submit.prevent="submit">

                                <div class="mb-3">
                                    <label class="form-label" for="password-input">Password lama</label>
                                    <div class="position-relative auth-pass-inputgroup mb-3">
                                        <input v-model="auth.old_password" :type="type.old_password"
                                            class="form-control pe-5" />
                                        <button @click="addonPassword('old_password')"
                                            class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                                            type="button">
                                            <i class="ri-eye-fill align-middle"></i>
                                        </button>
                                    </div>
                                </div>

                                <div class="mb-3">
                                    <label class="form-label" for="password-input">Password Baru</label>
                                    <div class="position-relative auth-pass-inputgroup mb-3">
                                        <input v-model="auth.new_password" :type="type.new_password"
                                            class="form-control pe-5" />
                                        <button @click="addonPassword('new_password')"
                                            class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                                            type="button">
                                            <i class="ri-eye-fill align-middle"></i>
                                        </button>
                                    </div>
                                </div>

                                <div class="mb-3">
                                    <label class="form-label" for="password-input">Konfirmasi Password Baru</label>
                                    <div class="position-relative auth-pass-inputgroup mb-3">
                                        <input v-model="auth.new_password_confirmation"
                                            :type="type.new_password_confirmation" class="form-control pe-5" />
                                        <button @click="addonPassword('new_password_confirmation')"
                                            class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                                            type="button">
                                            <i class="ri-eye-fill align-middle"></i>
                                        </button>
                                    </div>
                                </div>

                                <div class="float-end">
                                    <router-link :to="{ name: 'user.profile' }" type="button"
                                        class="btn btn-warning waves-effect waves-light me-2"><i
                                            class="bx bx-chevrons-left font-size-16 align-middle me-2"></i>
                                        Kembali
                                    </router-link>
                                    <button :disabled="load" type="submit"
                                        class="btn btn-primary waves-effect waves-light">
                                        <i class="bx font-size-16 align-middle me-2"
                                            :class="{ 'bx-loader bx-spin': load, 'bx-save': !load }"></i>
                                        Simpan
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swal from 'sweetalert2';
import { endpoint } from '../../host';
import errorService from '../../services/error-service';
import httpService from '../../services/http-service';

export default {
    components: {
    },
    created() {
    },

    data() {
        return {
            load: false,
            auth: {
                old_password: null,
                new_password: null,
                new_password_confirmation: null,
                _method: 'PUT',
            },
            type: {
                new_password: "password",
                old_password: "password",
                new_password_confirmation: "password",
            },
        };
    },
    methods: {
        async submit() {
            try {
                this.load = true;
                let res = await httpService.post(
                    endpoint.auth + `/edit-password`,
                    this.auth
                );
                this.load = false;
                if (res.status == 200) {
                    Swal.fire({
                        title: "Berhasil",
                        text: "data berhasil disimpan",
                        icon: "success",
                    });
                    this.auth.old_password = null;
                    this.auth.new_password = null;
                    this.auth.new_password_confirmation = null;
                }
            } catch (error) {
                this.load = false;
                errorService.displayError(error);
            }
        },
        addonPassword(a) {
            if (a == 'old_password') {
                this.type.old_password == "password"
                    ? (this.type.old_password = "text")
                    : (this.type.old_password = "password");
            } else if (a == 'new_password') {
                this.type.new_password == "password"
                    ? (this.type.new_password = "text")
                    : (this.type.new_password = "password");
            } else if (a == 'new_password_confirmation') {
                this.type.new_password_confirmation == "password"
                    ? (this.type.new_password_confirmation = "text")
                    : (this.type.new_password_confirmation = "password");
            }
        },
    },
};
</script>

<style>
</style>